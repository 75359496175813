<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 948px">
      <div class="layer_head">
        <strong class="tit_popup">구매 요청 정보 불러오기</strong>
      </div>
      <div class="layer_body">
        <div class="tbl_comm tbl_list tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <col style="width: 170px" />
                <col style="width: 248px" />
                <col style="width: 224px" />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col" style="width: 52px">
                    <div class="box_choice type_alone">
                      <!-- 텍스트 없이 아이콘만 있는 타입 type_alone 클래스 추가 -->
                      <div class="item_choice">
                        <!-- disabled 상태시 disabled 클래스 추가 -->
                        <input
                          id="checkLineAll"
                          v-model="checkAll"
                          type="checkbox"
                          class="inp_choice"
                          name="checkLineAll"
                        />
                        <label class="lab_choice" for="checkLineAll">
                          <span class="ico_account ico_check" />
                        </label>
                      </div>
                    </div>
                  </th>
                  <th style="width: 170px">신청번호</th>
                  <th style="width: 248px">신청서명</th>
                  <th style="width: 248px">분류</th>
                  <th style="width: 186px">구매품목</th>
                </tr>
              </thead>
              <tbody>
                <PopPurchaseRequestLine
                  v-for="item in purchaseRequestDataList"
                  :key="item.requestCid"
                  :rowData="item"
                  :checkedNames.sync="checkedNames"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_medium btn_secondary"
            :disabled="checkedNames.length == 0"
            @click.prevent="$emit('onClickComplete', checkedNames)"
          >
            선택불러오기
          </button>
        </div>
        <!-- <slot></slot> X(창닫기) -->
      </div>
    </div>
  </div>
</template>
<script>
import PopPurchaseRequestLine from "./PopPurchaseRequestLine";

export default {
  name: "PopPurchaseRequest",
  components: { PopPurchaseRequestLine },
  data() {
    return {
      // searchForm: {},
      // totalPage: 0,
      // currentPage: 0,
      // docList: [],
      checkAll: false,
      checkedNames: [],
      purchaseRequestDataList: [
        // api 연동 전 임시 데이터
        {
          requestCid: "2020125001",
          title: "구매(입찰)관리 시스템 외주 개발 건1",
          catagorys: "물품>하드웨어>서버",
          productNm: "구매품목명1",
        },
        {
          requestCid: "2020125002",
          title: "구매(입찰)관리 시스템 외주 개발 건2",
          catagorys: "물품>하드웨어>서버",
          productNm: "구매품목명2",
        },
        {
          requestCid: "2020125003",
          title: "구매(입찰)관리 시스템 외주 개발 건3",
          catagorys: "물품>하드웨어>서버",
          productNm: "구매품목명3",
        },
        {
          requestCid: "2020125004",
          title: "구매(입찰)관리 시스템 외주 개발 건4",
          catagorys: "물품>하드웨어>서버",
          productNm: "구매품목명4",
        },
        {
          requestCid: "2020125005",
          title: "구매(입찰)관리 시스템 외주 개발 건5",
          catagorys: "물품>하드웨어>서버",
          productNm: "구매품목명5",
        },
        {
          requestCid: "2020125006",
          title: "구매(입찰)관리 시스템 외주 개발 건6",
          catagorys: "물품>하드웨어>서버",
          productNm: "구매품목명6",
        },
        {
          requestCid: "2020125007",
          title: "구매(입찰)관리 시스템 외주 개발 건7",
          catagorys: "물품>하드웨어>서버",
          productNm: "구매품목명7",
        },
        {
          requestCid: "2020125008",
          title: "구매(입찰)관리 시스템 외주 개발 건8",
          catagorys: "물품>하드웨어>서버",
          productNm: "구매품목명8",
        },
      ],
    };
  },

  // 임시
  // watch:{
  //   checkedNames(){
  //     console.log('checkedNames',this.checkedNames.length , this.purchaseRequestDataList.length);
  //     if(this.checkedNames.length === this.purchaseRequestDataList.length){
  //       this.checkAll = true;
  //     }else if(this.checkAll && this.checkedNames.length != this.purchaseRequestDataList.length){
  //       this.checkAll = false;
  //     }
  //   },
  //   checkAll(to,from){
  //     // cheked on
  //     if( to && !from ){
  //       if(this.checkedNames.length != this.purchaseRequestDataList.length){
  //         this.checkedNames = this.purchaseRequestDataList;
  //       }
  //     }
  //   },
  // },
  methods: {},
};
</script>
